import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Segment, Header } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layouts/PageLayout'
import FeedbackSteps from '../components/Feedback/FeedbackSteps'
import StartFeedback from '../components/Feedback/StartFeedback'
import ItemFeedback from '../components/Feedback/ItemFeedback'

import AboutFeedback from '../components/Feedback/AboutFeedback'
import { fetchFeedbackQuestions, fetchFeedbackMeta } from '../utils/feedback'
import { getSearchParams } from '../utils/helpers'
import { feedbackActions } from '../components/Feedback/state'

class Feedback extends Component {
  state = { questions: [], title: '', loading: true, error: false }

  componentDidMount() {
    const params = getSearchParams(window.location.search)
    const dossierId = params['id']

    this.fetchDossier(dossierId)
      .then(({ questions, meta: { title, dossierNumber, employeeCode, travellers } }) => {
        this.props.setMeta({ dossierNumber, employeeCode, travellers })
        this.setState({ loading: false, error: false, questions, title, dossierNumber, employeeCode })
      })
      .catch(reason => {
        console.log(reason)
        this.setState({ loading: false, error: true })
      })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentIndex !== this.props.currentIndex) {
      typeof window !== 'undefined' && window.scrollTo(0, 0)
    }
  }

  async fetchDossier(dossierId) {
    const questions = await fetchFeedbackQuestions(dossierId)
    const meta = await fetchFeedbackMeta(dossierId)

    return { questions, meta }
  }

  render() {
    const { t, i18n, pageContext } = this.props
    const { dossierNumber, questions, title, loading, error } = this.state

    const questionsSteps = questions.map(({ step }) => step)
    const steps = ['start', ...questionsSteps, 'about']

    return (
      <Layout pageContext={pageContext}>
        <Helmet>
          <html lang={i18n.language} />
          <title>Feedback {dossierNumber || ''}</title>
        </Helmet>

        <Segment className='lightgrey feedback' inverted basic vertical loading={loading}>
          <Container textAlign='center'>
            {error ? (
              <Header as='h1' color='red' textAlign='center'>{t('dossier.error')}</Header>
            ) : (
                <Segment padded style={{ paddingTop: 40, paddingBottom: 40 }}>
                  <FeedbackSteps steps={steps} />
                  <StartFeedback id='general' step='start' index={0} topic={t('feedback.general.topic')} question={title} />
                  {questions.map((question, idx) => (
                    <ItemFeedback key={question.step} step={question.step} index={idx + 1} question={question} />
                  ))}
                  <AboutFeedback id='tourasia' step='about' index={steps.length - 1} />
                </Segment>
              )}
          </Container>
        </Segment>
      </Layout>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ feedback: { currentIndex, answer } }) => {
  return {
    currentIndex
  }
}

const mapDispatchTopProps = {
  setMeta: feedbackActions.setMeta
}

export default withTranslation()(connect(mapStateToProps, mapDispatchTopProps)(Feedback))
