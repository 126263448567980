import React from 'react'
import { connect } from 'react-redux'

import { feedbackActions } from './state'

const FeedbackSteps = ({ steps, currentIndex, setStep }) => {
  return (
    <div className='steps'>
      {steps.map((current, index) => (
        <div key={current} className={`step ${index === currentIndex ? 'active' : ''}`} onClick={() => setStep(index)} />
      ))}
    </div>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ feedback: { currentIndex } }) => {
  return {
    currentIndex
  }
}

const mapDispatchTopProps = {
  setStep: feedbackActions.setStep
}

export default connect(mapStateToProps, mapDispatchTopProps)(FeedbackSteps)
