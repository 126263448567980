import React, { Component } from 'react'
import { Icon, Grid } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

class LikeBox extends Component {
  state = {
    likes: this.props.likes || {}
  }

  handleLikeChange = (like, value) => {
    const { onChange } = this.props

    this.setState(prev => ({ likes: { ...prev.likes, [like]: prev.likes[like] === value ? 0 : value } }), () => onChange ? onChange(this.state.likes) : true)
  }

  render () {
    const { likes } = this.state
    const { options, t } = this.props

    return (
      <div className='like-box'>
        <Grid padded stackable columns='equal'>
          {options.map(option => (
            <Grid.Column key={option} className='like-button'>
              <div>{t(`feedback.likes.${option}`)}</div>
              <div>
                <Icon
                  link
                  size='big'
                  color={likes[option] === 1 ? 'red' : 'grey'}
                  className='ta-like'
                  onClick={() => this.handleLikeChange(option, 1)}
                />
                <Icon
                  link
                  size='big'
                  color={likes[option] === -1 ? 'red' : 'grey'}
                  className='ta-like'
                  style={{ transform: 'rotate(-180deg)' }}
                  onClick={() => this.handleLikeChange(option, -1)}
                />
              </div>
            </Grid.Column>
          ))}
        </Grid>
      </div>
    )
  }
}

export default withTranslation()(LikeBox)
