import React, { Component } from 'react'
import { Header, TextArea, Segment, Form } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import { ButtonIcon, ButtonIconGroup } from '../ButtonIcon'

class PickBox extends Component {
  state = {
    picked: this.props.picked || {}
  }

  handlePickChange = ({ title, placeholder }) => {
    const { onChange } = this.props
    this.setState({ picked: { title, placeholder } }, () => onChange(this.state.picked))
  }

  handleCommentChange = (comment) => {
    const { onChange } = this.props
    this.setState(prev => ({ picked: { ...prev.picked, comment } }), () => onChange(this.state.picked))
  }

  render () {
    const { picked } = this.state
    const { title, picks, t } = this.props

    if (!picks) {
      return ''
    }

    return (
      <Segment className='lightgrey pick-box' inverted style={{ marginBottom: 40 }}>
        <Header as='h2' color='red' className='light'>{title}</Header>
        <ButtonIconGroup center>
          {picks.map(({ title, placeholder }) => (
            <ButtonIcon
              key={title}
              title={t(`feedback.about.picks.${title}`)}
              inline
              className={`option-button ${title === picked.title ? 'active' : ''}`}
              onClick={() => this.handlePickChange({ title, placeholder })}
            />
          ))}
        </ButtonIconGroup>
        {picked.placeholder &&
          <Form className='option-comment'>
            <TextArea
              autoHeight
              placeholder={`${picked.placeholder} z.B. XYZ`}
              onChange={e => this.handleCommentChange(e.target.value)}
              value={picked.comment || ''}
            />
          </Form>
        }
      </Segment>
    )
  }
}

export default withTranslation()(PickBox)
