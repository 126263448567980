import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'

class RateBox extends Component {
  state = {
    rating: this.props.rating
  }

  handleRateChange = (rating) => {
    const { onChange } = this.props

    this.setState(prev => ({ rating: rating === prev.rating ? 0 : rating }), () => onChange ? onChange(this.state.rating) : true)
  }

  render () {
    const { rating } = this.state
    const { title, range = 5 } = this.props

    return (
      <div className={`${title ? 'rate-box' : ''}`}>
        <Grid padded stackable columns='equal'>
          {title &&
          <Grid.Column textAlign='right' className='title'>
            <span className='color-black'>{title}:</span>
          </Grid.Column>
          }
          <Grid.Column textAlign={`${title ? 'left' : 'center'}`}>
            {Array(range).fill(0).map((_, idx) => (
              <span
                key={idx}
                onClick={() => this.handleRateChange(idx + 1)}
                className={`rate-button ${idx + 1 <= rating ? 'active' : ''}`}
              />
            ))}
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default RateBox
