import React from 'react'
import { withTranslation } from 'react-i18next'
import { Header, Button, Icon, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'

import RateBox from './RateBox'
import { feedbackActions } from './state'

const StartFeedback = ({ t, id, setStep, setRating, answer, index, step, currentIndex, topic, question }) => {
  if (index !== currentIndex) {
    return ''
  }

  const ratingAnswer = answer[step] && answer[step][id] && answer[step][id].rating

  return (
    <div>
      <Header as='h1' color='red' className='light'>
        {topic}
        <Header.Subheader>{question}</Header.Subheader>
      </Header>
      <RateBox
        rating={ratingAnswer}
        onChange={rate => setRating({ id, step, value: rate })}
      />
      <Grid stackable>
        <Grid.Column width={12} />
        <Grid.Column width={4}>
          <Button color='red' icon labelPosition='right' inverted fluid onClick={() => setStep(index + 1)}>
            {t('feedback.next')} <Icon className='ta-arrow-1-right' link />
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ feedback: { currentIndex, answer } }) => {
  return {
    currentIndex,
    answer
  }
}

const mapDispatchTopProps = {
  setStep: feedbackActions.setStep,
  setRating: feedbackActions.setRating
}

export default withTranslation()(connect(mapStateToProps, mapDispatchTopProps)(StartFeedback))
