import React from 'react'
import { withTranslation } from 'react-i18next'
import { Grid, Header, TextArea, Icon, Form, Button, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'

import LikeBox from './LikeBox'
import RateBox from './RateBox'
import { feedbackActions } from './state'

const ItemFeedback = ({ t, setStep, setLikes, setRating, setComment, answer, step, index, currentIndex, question }) => {
  const { title, subtitle, rating, likes, items } = question

  if (index !== currentIndex) {
    return ''
  }

  return (
    <div>
      <Header as='h1' color='red' className='light'>
        {title}
        <Header.Subheader>{subtitle}</Header.Subheader>
      </Header>
      {items.length > 0 &&
        items.map(({ id, beginDate, endDate, destCode, serviceCode, location, topic }) => (
          <Segment key={id} className='lightgrey' inverted style={{ marginBottom: 20 }}>
            <Header as='h3' color='red'>
              {location &&
                <Header.Subheader className='color-gray'>
                  <Icon className='ta-locationmark' /> {location.filter(l => !!l).join(' | ')}
                </Header.Subheader>
              }
              {topic}
            </Header>
            {rating &&
              <RateBox
                rating={answer[step] && answer[step][id] && answer[step][id].rating}
                title={t('feedback.item.rate')}
                onChange={rate => setRating({ id, serviceName: topic, beginDate, endDate, destCode, serviceCode, step, value: rate })}
              />
            }
            {likes &&
              <LikeBox
                likes={answer[step] && answer[step][id] && answer[step][id].likes}
                options={likes}
                onChange={likes => setLikes({ id, serviceName: topic, beginDate, endDate, destCode, serviceCode, step, value: likes })}
              />
            }
            <Form>
              <TextArea
                autoHeight
                placeholder={t('feedback.item.comment-placeholder')}
                value={answer[step] && answer[step][id] && answer[step][id].comment}
                onChange={e => setComment({ id, serviceName: topic, beginDate, endDate, destCode, serviceCode, step, value: e.target.value })}
              />
            </Form>
          </Segment>
        ))
      }
      <Grid stackable>
        <Grid.Column width={8} />
        <Grid.Column width={4}>
          <Button basic icon labelPosition='left' color='grey' fluid onClick={() => setStep(index - 1)}>
            <Icon className='ta-arrow-1-left' link /> {t('feedback.back')}
          </Button>
        </Grid.Column>
        <Grid.Column width={4}>
          <Button color='red' icon labelPosition='right' inverted fluid onClick={() => setStep(index + 1)}>
            {t('feedback.next')} <Icon className='ta-arrow-1-right' link />
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ feedback: { currentIndex, answer } }) => {
  return {
    currentIndex,
    answer
  }
}

const mapDispatchTopProps = {
  setStep: feedbackActions.setStep,
  setRating: feedbackActions.setRating,
  setLikes: feedbackActions.setLikes,
  setComment: feedbackActions.setComment
}

export default withTranslation()(connect(mapStateToProps, mapDispatchTopProps)(ItemFeedback))
