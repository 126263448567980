import React, { Component } from 'react'
import { Header, TextArea, Grid, Button, Icon, Form, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { navigate } from 'gatsby'

import { ButtonIcon } from '../ButtonIcon'
import PickBox from './PickBox'
import { feedbackActions } from './state'
import { postFeedback } from '../../utils/feedback'
import { modalActions } from '../Modals/state'

class AboutFeedback extends Component {
  state = { sending: false }

  sendFeedback = async (answer) => {
    this.setState({ sending: true })

    const { toggleModal, i18n, t } = this.props
    const response = await postFeedback(answer)

    this.setState({ sending: false })

    if (response.status === 200 && response.statusText === 'OK') {
      navigate(`/${i18n.language}/feedback-thanks`)
    } else if (response.status === 200 && response.statusText !== 'OK') {
      toggleModal('notification', { type: 'error', text: response.statusText })
    } else {
      toggleModal('notification', { type: 'error', text: t('feedback.error') })
    }
  }

  render () {
    const { sending } = this.state
    const { setStep, setAnswer, answer, id, currentIndex, index, step, t } = this.props

    if (index !== currentIndex) {
      return ''
    }

    const recommendAnswer = answer[step] && answer[step][id] && answer[step][id].recommend
    const commentAnswer = answer[step] && answer[step][id] && answer[step][id].comment
    const pickAnswer = answer[step] && answer[step][id] && answer[step][id].findout

    const picks = [
      { title: 'website' },
      { title: 'catalog' },
      { title: 'trip' },
      { title: 'fair', placeholder: t('feedback.about.picks.fair-placeholder') },
      { title: 'recommendation', placeholder: t('feedback.about.picks.rec-placeholder') },
      { title: 'others', placeholder: t('feedback.about.picks.others-placeholder') }
    ]

    return (
      <div>
        <Header as='h1' color='red' className='light'>
          {t('feedback.about.topic')}
          <Header.Subheader>{t('feedback.about.question')}</Header.Subheader>
        </Header>

        <PickBox
          title={t('feedback.about.pickbox')}
          picks={picks}
          picked={pickAnswer}
          onChange={pick => setAnswer({ step, id, value: pick, type: 'findout' })}
        />

        <Grid>
          <Grid.Row stretched>
            <Grid.Column mobile={16} tablet={8} computer={8} style={{ marginBottom: 40 }}>
              <Segment className='lightgrey' inverted basic>
                <Header as='h2' color='red' className='light'>{t('feedback.about.answer')}</Header>
                <Grid columns='equal'>
                  <Grid.Column>
                    <ButtonIcon
                      title={t('feedback.about.yes')}
                      className={`option-button ${recommendAnswer === 1 ? 'active' : ''}`}
                      onClick={() => setAnswer({ step, id, value: 1, type: 'recommend' })}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <ButtonIcon
                      title={t('feedback.about.no')}
                      className={`option-button ${recommendAnswer === -1 ? 'active' : ''}`}
                      onClick={() => setAnswer({ step, id, value: -1, type: 'recommend' })}
                    />
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} style={{ marginBottom: 40 }}>
              <Segment color='red'>
                <Header as='h2' color='red' className='light'>{t('feedback.about.tip-header')}</Header>
                <p>{t('feedback.about.tip-text')}</p>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Segment className='lightgrey' inverted style={{ marginBottom: 40 }}>
          <Header as='h2' color='red' className='light'>{t('feedback.about.comment')}</Header>
          <Form>
            <TextArea
              autoHeight
              placeholder={t('feedback.about.comment-placeholder')}
              value={commentAnswer}
              onChange={e => setAnswer({ step, id, value: e.target.value, type: 'comment' })}
            />
          </Form>
        </Segment>

        <Grid stackable>
          <Grid.Column width={8} />
          <Grid.Column width={4}>
            <Button basic icon labelPosition='left' color='grey' fluid onClick={() => setStep(index - 1)}>
              <Icon className='ta-arrow-1-left' link /> {t('feedback.back')}
            </Button>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button loading={sending} color='red' icon labelPosition='right' inverted fluid onClick={() => this.sendFeedback(answer)}>
              {t('feedback.send')} <Icon className='ta-arrow-1-right' link />
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ feedback: { currentIndex, answer } }) => {
  return {
    currentIndex,
    answer
  }
}

const mapDispatchTopProps = {
  setStep: feedbackActions.setStep,
  setAnswer: feedbackActions.setAnswer,
  toggleModal: modalActions.toggleModal
}

export default withTranslation()(connect(mapStateToProps, mapDispatchTopProps)(AboutFeedback))
